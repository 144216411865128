import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.min";
import '../../node_modules/slick-carousel/slick/slick.min';
import '../../node_modules/fittext/dist/jquery.fittext';
import "../../node_modules/inputmask/dist/jquery.inputmask.min";
import gsap from '../../node_modules/gsap/dist/gsap.min';
import ScrollToPlugin from '../../node_modules/gsap/dist/ScrollToPlugin.min';
import "../../node_modules/jquery-idletimer/dist/idle-timer.min";
import "../../node_modules/countdowntimer/dist/js/jQuery.countdownTimer.min";
import '../../node_modules/jquery-validation/dist/jquery.validate.min';
import imagesLoaded from '../../node_modules/imagesloaded/imagesloaded.pkgd.min';
import '../../node_modules/jquery-match-height/dist/jquery.matchHeight-min.js';

var $ = require('jquery');
gsap.registerPlugin(ScrollToPlugin);
imagesLoaded.makeJQueryPlugin( $ );

(function( root, $, undefined ) {
"use strict";

$(function () {

var PathUrl = $("body").attr("data-path"),
SiteUrl = $("body").attr("data-url"),
WindowHeight = function(){
$('.get_height').css({'min-height':  window.innerHeight+ 'px'});
$('.get_height_total').css({'height':  window.innerHeight+ 'px'});
$('.home-slider, .int-single-medios').css("padding-top",$(".header").outerHeight(true));
}

$('.wrapper').imagesLoaded( { background: true })
.always( function( instance ) {
setTimeout(function() { 
$(".preload").fadeOut("fast");
$("html, body").css("overflow","visible");
}, 2500);
});

const MenuMobile = new bootstrap.Offcanvas('#MobileMenu');
const MenuMobileAct = document.getElementById('MobileMenu')
MenuMobileAct.addEventListener('hide.bs.offcanvas', event => {
$('body').removeClass('menu-open');
});

$('.bt-menu').on('click', function(e) {
e.preventDefault();
$('body').toggleClass('menu-open');
if($('body').hasClass('menu-open')){
MenuMobile.show();
}else{
MenuMobile.hide();
}
});

$(window).on("scroll",function(){
$('a.btndesk').each(function(){
var sectionid = $(this).attr('href'),
idsec = sectionid.split("#");
if ($("#"+idsec[1]).position().top <= $(window).scrollTop() + $(".header").outerHeight(true)){
$(".btndesk").removeClass("active");
$(".btn-"+idsec[1]).addClass("active");
}
});
});

$('.medio-item-title').matchHeight({
    byRow: false,
});

$(".desk-menu a").on("click",function(e){
e.preventDefault();
var sectionid = $(this).attr("href");
gsap.to(window, { duration: 0.8, scrollTo: { y: sectionid, offsetY: $(".header").outerHeight(true)-30 } });
});

$(".desk-mob a").on("click",function(e){
e.preventDefault();
var sectionid = $(this).attr("href");
gsap.to(window, { duration: 0.8, scrollTo: { y: sectionid, offsetY: $(".header").outerHeight(true)-30 } });
$('body').toggleClass('menu-open');
MenuMobile.hide();
});

$(".gotocontacto").on("click",function(){
gsap.to(window, { duration: 0.8, scrollTo: { y: "#contacto", offsetY: $(".header").outerHeight(true)-30 } });
});

$('.home-slider').on('init', function(e, slick) {
var $firstAnimatingElements = $('div.slide-home:first-child').find('[data-animation]');
doAnimations($firstAnimatingElements);    
});
$('.home-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
var $animatingElements = $('div.slide-home[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
doAnimations($animatingElements);    
});

$('.home-slider').slick({
infinite: true,
speed: 600,
autoplay: false,
autoplaySpeed: 10000,
slidesToShow: 1,
fade: true,
dots: true,
responsive: [
{
breakpoint: 1024,
settings: {
arrows: true,
}
},
{
breakpoint: 600,
settings: {
arrows: false,
}
},
{
breakpoint: 480,
settings: {
arrows: false,
}
}
]
});

function doAnimations(elements) {
var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
elements.each(function() {
var $this = $(this);
var $animationDelay = $this.data('delay');
var $animationType = 'animate__animated ' + $this.data('animation');
$this.css({
'animation-delay': $animationDelay,
'-webkit-animation-delay': $animationDelay
});
$this.addClass($animationType).one(animationEndEvents, function() {
$this.removeClass($animationType);
});
});
}

$(window).on('resize', function(){
WindowHeight();
});

$(window).on('load', function(){
WindowHeight();
$('a.btndesk').each(function(){
var sectionid = $(this).attr('href'),
idsec = sectionid.split("#");
if ($("#"+idsec[1]).position().top <= $(window).scrollTop() + $(".header").outerHeight(true)){
$(".btndesk").removeClass("active");
$(".btn-"+idsec[1]).addClass("active");
}
});
});

$(".info-slide h2").flowtype({
fontRatio : 15
});

$(".info-slide h3").flowtype({
fontRatio : 18
});

$(".info-slide p").flowtype({
fontRatio : 25
});


$(".que-hacemos .titulo-main").flowtype({
fontRatio : 21,
minFont   : 40,
});

$(".wrap-parallax h2").flowtype({
fontRatio : 19,
minFont   : 40,
});

$(".nosotros .fundadora h3").flowtype({
fontRatio : 19,
minFont   : 25,
});

$(".testimonios .main-title").flowtype({
fontRatio : 19,
minFont   : 40,
});

$(".contacto .main-title").flowtype({
fontRatio : 17,
minFont   : 40,
});

$(".modal .modal-content .modal-body span h2").flowtype({
fontRatio : 14,
minFont   : 40,
});

$(".single-title").flowtype({
fontRatio : 19,
minFont   : 40,
});

$(".entradas-main-title").flowtype({
fontRatio : 30,
minFont   : 40,
});
    
$(".linkedin .main-title").flowtype({
fontRatio : 30,
minFont   : 40,
});

$('.testimonio-slider').slick({
infinite: true,
slidesToShow: 3,
slidesToScroll: 1,
dots: true,
responsive: [
{
breakpoint: 1024,
settings: {
slidesToShow: 3,
slidesToScroll: 1,
infinite: true,
dots: true
}
},
{
breakpoint: 600,
settings: {
slidesToShow: 2,
slidesToScroll: 2
}
},
{
breakpoint: 480,
settings: {
slidesToShow: 1,
slidesToScroll: 1
}
}
]
});

$('.medios-slider').slick({
infinite: true,
slidesToShow: 3,
slidesToScroll: 1,
dots: true,
responsive: [
{
breakpoint: 1140,
settings: {
slidesToShow: 3,
slidesToScroll: 1,
arrows: true,
}
},
{
breakpoint: 1024,
settings: {
slidesToShow: 2,
slidesToScroll: 1,
arrows: true,
}
},
{
breakpoint: 768,
settings: {
slidesToShow: 1,
slidesToScroll: 2,
arrows: false,
}
},
{
breakpoint: 480,
settings: {
slidesToShow: 1,
slidesToScroll: 1,
arrows: false,
}
},
{
breakpoint: 320,
settings: {
slidesToShow: 1,
slidesToScroll: 1,
arrows: false,
}
}
]
});

$('.entradas-slider').slick({
infinite: true,
slidesToShow: 3,
slidesToScroll: 1,
dots: true,
responsive: [
{
breakpoint: 1140,
settings: {
slidesToShow: 3,
slidesToScroll: 1,
infinite: true,
dots: true
}
},
{
breakpoint: 1024,
settings: {
slidesToShow: 2,
slidesToScroll: 1,
infinite: true,
dots: true
}
},
{
breakpoint: 768,
settings: {
slidesToShow: 1,
slidesToScroll: 2
}
},
{
breakpoint: 480,
settings: {
slidesToShow: 1,
slidesToScroll: 1
}
},
{
breakpoint: 320,
settings: {
slidesToShow: 1,
slidesToScroll: 1
}
}
]
});


var map = gsap.timeline({paused:true});
map.to(".main-form", {opacity:0, autoAlpha:0, duration: 1, ease: "expo.inOut"},0)
.to(".map", {opacity:1, pointerEvents:"auto", duration: 1, ease: "expo.inOut"},0)
.to(".item-contact-metod", {opacity:0, duration: 0, autoAlpha:0, display:"none", ease: "expo.inOut"},0.7)
.to(".action-map", {opacity:1, autoAlpha:1, duration: 1, ease: "expo.inOut"},0.5);

$(".show-map").on("click",function(){
$(this).toggleClass('active');
map.timeScale(1).play(0);
});

$(".close-map").on("click",function(e){
e.preventDefault();
map.timeScale(1).reverse(0);
$('.show-map').toggleClass('active');
});


/*ChatBox*/
$.validator.addMethod(
"regex",
function(value, element, regexp) 
{
if (regexp.constructor != RegExp)
regexp = new RegExp(regexp);
else if (regexp.global)
regexp.lastIndex = 0;
return this.optional(element) || regexp.test(value);
},
"Ingresa un email valido."
);

$("#EnterChat").validate({ 
onfocusout: false,
rules: {
chatusername: { 
required: true,
minlength: 4,
maxlength: 20
},  
chatuseremail: {
required: true,
email: true,
regex: /^[A-Za-z0-9_]+\@[A-Za-z0-9_]+\.[A-Za-z0-9_]+/, 
}
},
messages: {
chatusername: {
required: "Ingres tu nombre",
minlength: "Mínimo 4 caracteres",
maxlength: "Maximo 20 caracteres"
},
chatuseremail: {
required: "Ingresa tu email",
email: "Ingresa un email valido"
}
},
submitHandler: function(form){
$(".chat-loader").fadeIn("fast").css("display","flex");
var action = "EnterChat",
chatname = $("#chat-username").val(),	
chatemail = $("#chat-useremail").val(),	
DataUser = {action:action,chatname:chatname,chatemail:chatemail};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
var dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).addClass("message-disabled");
$("#"+dataid).find(".btnpreg").addClass("message-disabled");
$(".messages").empty();
gsap.to(".chat-userdata", {opacity:0, autoAlpha:0, duration: 0.8, ease:"elastic.out(1,1)"});
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
setTimeout(function() { 
var update = "UpdateChat",
mainchat = $("#chat").html(),
DataUser = {action:update,mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {
}
});
$("#chat-username").val("");
$("#chat-useremail").val("");
}, 50);
$('.messages').scrollTop($('.messages')[0].scrollHeight);
}, 1000);
}else{

}
}
});
},
invalidHandler: function(event, validator) {
var errors = validator.numberOfInvalids();
if (errors) { 
var message = errors == 1 ? ' Error: Te perdiste 1 campo. Ha sido destacado' : ' Error: Te perdiste '+ errors +' campos. Se han destacado';
$("div#formError span#Mensaje").html(message);
$("div#formError").removeClass("hidden");
}
},
highlight: function(element, errorClass) {

},
errorElement: "div",
errorClass: "alert alert-danger",
});

$("#chat-useremail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

var chat = gsap.timeline({paused:true});
chat.to(".chat", {marginBottom: "0", opacity:1, autoAlpha:1, display:'flex', duration: 0.8, ease: "expo.inOut",onReverseComplete: function() {
$(".chat").hide();
},});

$(".chat-btn").on("click",function(e){
e.preventDefault();
$('.messages').scrollTop($('.messages')[0].scrollHeight);
$(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
$(".inputemail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});
$(this).toggleClass("active");
if($(this).hasClass("active")){
chat.play();
}else{
chat.reverse(0);
}
});

$("#chat").idleTimer({
timeout:600000, 
idle:true
});

var idtime;

$('#chat').on("idle.idleTimer", function (event, elem, obj) {
event.stopPropagation();
var action = "InactiveChat",
mainchat = $("#chat").html(),
DataUser = {action:action, mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
idtime = "#time-"+response.time+"";
$(idtime).countdowntimer({
seconds : 30,
size : "lg",
padZeroes : false,
displayFormat : "S",
timeUp : timeIsUp,
});
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
}, 100);
}, 2000);

}else{

}
}
});

});

$('#chat').on("active.idleTimer", function (event) {
event.stopPropagation();
if(idtime){
$(idtime).countdowntimer("pause","pause");
}
});

function timeIsUp() {
var action = "EndChat",
mainchat = $("#chat").html(),
DataUser = {action:action, mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(idtime).countdowntimer("destroy");
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".chat-btn").toggleClass("active");
chat.reverse(0);
setTimeout(function() { 
$(".chat-userdata").removeClass("hideform").addClass("showform");
$(".chat-loader").css("display","none");
gsap.to(".chat-userdata", {opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
}, 500); 
}, 2000);   
}, 2000);   
}
}
});
}

$("body").on("click",".BackMenu",function(){
var dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).addClass("message-disabled");
var action = "BackChat",
data_id = dataid,
DataUser = {action:action, data_id:data_id};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
setTimeout(function() { 
    var update = "UpdateChat",
    mainchat = $("#chat").html(),
    DataUser = {action:update,mainchat:mainchat};
    $.ajax({
    data:  DataUser,
    url:   SiteUrl+"/json",
    type:  'post',
    cache: false,
    success:function(response) {
    }
    });
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
}, 50);
}, 1000);
}else{

}
}
});
});

$("body").on("click",".EndChat",function(){
var dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).find("button").prop('disabled', true);
$("#"+dataid).addClass("message-disabled");
var action = "EndChat",
mainchat = $("#chat").html(),
DataUser = {action:action, mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(idtime).countdowntimer("destroy");
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".chat-btn").toggleClass("active");
chat.reverse(0);
setTimeout(function() { 
$(".chat-userdata").removeClass("hideform").addClass("showform");
$(".chat-loader").css("display","none");
gsap.to(".chat-userdata", {opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
}, 500); 
}, 2000);   
}, 2000);   
}
}
});
});


$("body").on("click",".btnpreg",function(){
var dt = new Date(),
time = dt.getHours() + ":" + dt.getMinutes(),
dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).find("button").prop('disabled', true);
$("#"+dataid).addClass("message-disabled");
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message user"><p>'+$(this).text()+'</p><time datetime="'+time+'">'+time+'</time></div>');
gsap.to(".user:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
var action = "GetRespuesta",
pregindex = $(this).attr("data-preg"),
pregtype = $(this).attr("data-tipo"),
DataUser = {action:action,pregindex:pregindex, pregtype:pregtype};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
var update = "UpdateChat",
mainchat = $("#chat").html(),
DataUser = {action:update,mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "updatechat"){
}
}
});
if(response.type == "telefono"){
$(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
}else if(response.type == "email"){
$(".inputemail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});
}
}, 300);
}, 2000);
}else{

}
}
});
});


$("body").on("click",".btnpregrep",function(){
var dt = new Date();
var time = dt.getHours() + ":" + dt.getMinutes();
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message user"><p>'+$(this).text()+'</p><time datetime="'+time+'">'+time+'</time></div>');
gsap.to(".user:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
var action = "GetRespuestaRep",
datapreg = $(this).attr("data-preg"),
dataindex = $(this).attr("data-index"),
DataUser = {action:action,datapreg:datapreg, dataindex:dataindex};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
var update = "UpdateChat",
mainchat = $("#chat").html(),
DataUser = {action:update,mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "updatechat"){
}
}
});
if(response.type == "telefono"){
$(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
}else if(response.type == "email"){
$(".inputemail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});
}
}, 300);
}, 2000);
}else{

}
}
});
});



$("body").on("click",".btndata",function(){

var datatype = $(this).attr("data-input"),
dataid = $(this).attr("data-id"),
lastItem = dataid.split("-").pop();

$("."+lastItem).remove();

if(datatype == "telefono"){

if($("#"+dataid).val().length < 4) {  
$('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu teléfono</div>');
return false;  
}
if($("#"+dataid).val().replace(/[^0-9]/g,"").length < 11) { 
$('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa un teléfono valido</div>');
return false;   
}

}else if(datatype == "email"){
if($("#"+dataid).val().length < 1) {  
$('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu email</div>'); 
return false;  
}  
if($("#"+dataid).val().indexOf('@', 0) == -1 || $("#"+dataid).val().indexOf('.', 0) == -1) {  
$('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">El email ingresado contiene errores.</div>'); 
return false;  
}

}else if(datatype == "mensaje"){
if($("#"+dataid).val().length < 10) {  
$('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu comentario.</div>'); 
return false;  
} 
}

if(datatype == "mensaje"){
$("#"+dataid).html($("#"+dataid).val()); 
}else{
$("#"+dataid).attr("value", $("#"+dataid).val()); 
}

gsap.to($("#"+lastItem), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+lastItem).find("button").prop('disabled', true);
$("#"+lastItem).addClass("message-disabled");

var action = "SaveData",
inputdata = $("#"+dataid).val(),	
typedata = datatype,
DataUser = {action:action,inputdata:inputdata,typedata:typedata};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
$(".typingload").remove();
gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
$(".messages").append(response.data);
gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
setTimeout(function() { 
var update = "UpdateChat",
mainchat = $("#chat").html(),
DataUser = {action:update,mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "updatechat"){
}
}
});
}, 300);
}, 2000);
}else{

}
}
});

});

/*End Chat*/

});

} ( this, jQuery ));